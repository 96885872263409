export function initializeSelectize() {
  jQuery(document).ready(function () {
    jQuery('select[name="lek"]').selectize({
      valueField: 'id',
      labelField: 'text',
      searchField: 'text',
      maxItems: 1,
      placeholder: 'Wyszukaj lek',
      create: false,
      load: function (query, callback) {
        // Ładowanie wszystkich opcji, gdy zapytanie jest puste
        if (!query.length) return callback()
        jQuery.ajax({
          url:
            uploader.wordpress_host +
            '/wp-content/themes/recepty/zapis_produktow.json',
          type: 'GET',
          dataType: 'json',
          error: function () {
            callback()
          },
          success: function (res) {
            console.log(res)

            callback(res)
          },
        })
      },
      render: {
        option: function (item) {
          return '<div>' + item.text + '</div>'
        },
        item: function (item) {
          return '<div>' + item.text + '</div>'
        },
      },
    })
  })
}

// Definiowanie funkcji globalnie
export function dataForm() {
  var dataForm = jQuery('#front-page-form').serialize()

  jQuery.post(
    uploader.ajax_url,
    {
      action: 'save_form_data',
      formData: dataForm,
    },
    function (response) {
      if (response.status === 'success') {
        window.location.href = response.redirect_url
      } else {
        alert('Wystąpił błąd podczas przetwarzania danych.')
      }
    }
  )
}

export function validation_error(element, message = 'nie wybrano opcji') {
  if (jQuery(element).next('.validation-error').length === 0) {
    const newEl = document.createElement('div')
    newEl.style.color = '#F53632'
    newEl.innerText = message
    newEl.classList.add('validation-error')
    jQuery(element).after(newEl)
  }
  const targetElement = jQuery(element).get(0)
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export function remove_validation_error(element) {
  if (jQuery(element).next('.validation-error').length > 0) {
    jQuery(element).next('.validation-error').remove()
  }
}

export function validate_forms() {
  let valid = true

  if (document.body.classList.contains('page-template-formularz')) {
    if (!validate_medicine_selection()) valid = false
    if (!validate_documentation()) valid = false
    if (!validate_allergies()) valid = false
    if (!validate_symptoms()) valid = false
    if (!validate_chronic_diseases()) valid = false

    if (!validate_operations()) valid = false
    if (!validate_permanent_medications()) valid = false
  } else if (
    document.body.classList.contains('page-template-formularz_dzien_po')
  ) {
    if (!validate_medicine_selection()) valid = false
    if (!validate_documentation()) valid = false
    if (!validate_last_period()) valid = false
    if (!validate_last_unprotected()) valid = false
    if (!validate_last_gynecologist_visit()) valid = false
    if (!validate_allergenes_or_chronic()) valid = false
    // if (!validate_additional_info()) valid = false
  } else if (
    document.body.classList.contains('page-template-formularz_antykoncepcja')
  ) {
    if (!validate_medicine_selection()) valid = false
    if (!validate_documentation()) valid = false
    // if (!validate_symptoms()) valid = false
    if (!validate_last_gynecologist_visit()) valid = false
    if (!validate_allergenes_or_chronic()) valid = false
  } else if (
    document.body.classList.contains('page-template-formularz_zwolnienie')
  ) {
    if (!validate_employment()) valid = false
    if (!validate_nip()) valid = false
    if (!validate_symptoms()) valid = false
    if (!validate_documentation()) valid = false
    // if (!validate_additional_info()) valid = false
  }

  return valid
}

// Walidacja wyboru leku
function validate_medicine_selection() {
  if (!document.body.classList.contains('page-template-formularz_dzien_po')) {
    var selectizeInstance = jQuery('select[name="choose-medicine"]')[0]
      .selectize
  }
  if (
    jQuery('input[name="choose-who"]:checked').val() === 'patient-decides' &&
    !selectizeInstance.getValue()
  ) {
    validation_error('._first-form', 'Wybierz produkt')
    return false
  } else {
    remove_validation_error('._first-form')
    return true
  }
}

// Walidacja objawów
function validate_symptoms() {
  if (jQuery('textarea[name="symptoms"]').val()?.trim() === '') {
    validation_error('textarea[name="symptoms"]', 'Uzupełnij pole dolegliwości')
    return false
  } else {
    remove_validation_error('textarea[name="symptoms"]')
    return true
  }
}

// Walidacja alergii
function validate_allergies() {
  if (
    jQuery('input[name="allergenes"]:checked').val() === 'yes' &&
    jQuery('textarea[name="allergies-description"]').val()?.trim() === ''
  ) {
    validation_error(
      'textarea[name="allergies-description"]',
      'Uzupełnij pole alergii'
    )
    return false
  } else {
    remove_validation_error('textarea[name="allergies-description"]')
    return true
  }
}

function validate_chronic_diseases() {
  if (
    jQuery('input[name="chronic-diseases"]:checked').val() === 'yes' &&
    jQuery('textarea[name="chronic-diseases-description"]').val()?.trim() === ''
  ) {
    validation_error(
      'textarea[name="chronic-diseases-description"]',
      'Uzupełnij to pole'
    )
    return false
  } else {
    remove_validation_error('textarea[name="chronic-diseases-description"]')
    return true
  }
}

// Walidacja dokumentacji
function validate_documentation() {
  if (
    jQuery('input[name="has-documentation"]:checked').val() === 'yes' &&
    window.uploadedFileCount > 0
  ) {
    remove_validation_error('input#dropzone-error')
    return true
  } else if (jQuery('input[name="has-documentation"]:checked').val() === 'no') {
    remove_validation_error('input#dropzone-error')
    return true
  } else {
    validation_error('input#dropzone-error', 'Podaj dokumentację')
    return false
  }
}

// Walidacja pola "last-period"
function validate_last_period() {
  if (jQuery('input[name="last-period"]').val()?.trim() === '') {
    validation_error(
      'input[name="last-period"]',
      'Uzupełnij datę ostatniej miesiączki'
    )
    return false
  } else {
    remove_validation_error('input[name="last-period"]')
    return true
  }
}

// Walidacja pola "last-unprotected"
function validate_last_unprotected() {
  if (jQuery('input[name="last-unprotected"]').val()?.trim() === '') {
    validation_error(
      'input[name="last-unprotected"]',
      'Uzupełnij datę ostatniego stosunku bez zabezpieczenia'
    )
    return false
  } else {
    remove_validation_error('input[name="last-unprotected"]')
    return true
  }
}

// Walidacja pola "last-gynecologist-visit"
function validate_last_gynecologist_visit() {
  if (jQuery('input[name="last-gynecologist-visit"]').val()?.trim() === '') {
    validation_error(
      'input[name="last-gynecologist-visit"]',
      'Uzupełnij datę ostatniej wizyty u ginekologa'
    )
    return false
  } else {
    remove_validation_error('input[name="last-gynecologist-visit"]')
    return true
  }
}

// Walidacja pola "allergenes-or-chronic"
function validate_allergenes_or_chronic() {
  if (
    jQuery('input[name="allergenes-or-chronic"]:checked').val() === 'yes' &&
    jQuery('textarea[name="chronic-diseases-description"]').val()?.trim() === ''
  ) {
    validation_error(
      'textarea[name="chronic-diseases-description"]',
      'Uzupełnij pole dotyczące przewlekłych chorób'
    )
    return false
  } else {
    remove_validation_error('textarea[name="chronic-diseases-description"]')
    return true
  }
}

// Walidacja pola "additional-info"
function validate_additional_info() {
  if (jQuery('textarea[name="additional-info"]').val()?.trim() === '') {
    validation_error(
      'textarea[name="additional-info"]',
      'Uzupełnij dodatkowe informacje'
    )
    return false
  } else {
    remove_validation_error('textarea[name="additional-info"]')
    return true
  }
}

function validate_nip() {
  const nipValue = jQuery('input[name="NIP"]').val()?.trim()

  // Sprawdzamy, czy NIP ma 10 cyfr
  const nipRegex = /^\d{10}$/

  if (!nipValue || !nipRegex.test(nipValue)) {
    validation_error('input[name="NIP"]', 'Podaj poprawny NIP (10 cyfr)')
    return false
  } else {
    remove_validation_error('input[name="NIP"]')
    return true
  }
}

function validate_employment() {
  const employmentValue = jQuery('select[name="employment"]').val()

  if (!employmentValue) {
    validation_error('select[name="employment"]', 'Wybierz opcję zatrudnienia')
    return false
  } else {
    remove_validation_error('select[name="employment"]')
    return true
  }
}

// od tąd pola dla głównego form konsultacji
function validate_operations() {
  if (
    jQuery('input[name="operations"]:checked').val()?.trim() === 'yes' &&
    jQuery('textarea[name="operations-description"]').val()?.trim() === ''
  ) {
    validation_error(
      'textarea[name="operations-description"]',
      'Uzupełnij informacje o operacjach'
    )
    return false
  } else {
    remove_validation_error('textarea[name="operations-description"]')
    return true
  }
}

function validate_permanent_medications() {
  if (
    jQuery('input[name="permanent-medications"]:checked').val()?.trim() ===
      'yes' &&
    jQuery('textarea[name="permanent-medications-description"]')
      .val()
      ?.trim() === ''
  ) {
    validation_error(
      'textarea[name="permanent-medications-description"]',
      'Uzupełnij informacje o lekach na stałe'
    )
    return false
  } else {
    remove_validation_error(
      'textarea[name="permanent-medications-description"]'
    )
    return true
  }
}

// Funkcja inicjująca
export function initializeUpload() {
  let select2LekID = new URLSearchParams(window.location.search).get('lek')

  jQuery(document).ready(function () {
    if (select2LekID) {
      jQuery('input[name="choose-who"][value="patient-decides"]').prop(
        'checked',
        true
      )
    }
    if (document.body.classList.contains('page-template-formularz')) {
      toggleSelectize()
      toggleDocs()
      toggleSymptoms()
      // obciążenia chorobowe:
      toggleDiseases()

      togglepermanentMeds()
      toggleOperations()
    } else if (
      // te same funkcje dla obu stron formularzy
      document.body.classList.contains('page-template-formularz_dzien_po') ||
      document.body.classList.contains('page-template-formularz_antykoncepcja')
    ) {
      toggleSelectize()
      toggleDocs()
      toggleChronicDiseases()
    } else if (
      document.body.classList.contains('page-template-formularz_zwolnienie')
    ) {
      toggleDocs()
    }
    // Tutaj można wywoływać funkcje bezpośrednio, np. validate_forms();
  })

  jQuery('input[name="choose-who"]').on('change', () => toggleSelectize())
  function toggleSelectize() {
    var selectMedicine = jQuery('select[name="choose-medicine"]')

    if (selectMedicine.length > 0 && selectMedicine[0].selectize) {
      var selectizeInstance = selectMedicine[0].selectize
      const whoValue = jQuery('input[name="choose-who"]:checked').val()

      if (whoValue === 'patient-decides') {
        selectizeInstance.enable() // Aktywacja pola
        selectizeInstance.clear() // Wyczyszczenie wyboru, jeśli potrzeba
        jQuery('select[name="how-much"]').prop('disabled', false).val('1')
        jQuery('#select-hide-element').show()
        jQuery('.how-much').show()

        // pokazanie pola 'medicine-used'
        jQuery('input[name="medicine-was-used"]')
          .prop('disabled', false)
          .val('1')
        jQuery('.medicine-was-used').show()
      } else {
        selectizeInstance.disable() // Dezaktywacja pola
        jQuery('select[name="how-much"]').prop('disabled', true).val('')
        jQuery('.how-much').hide()
        jQuery('#select-hide-element').hide()

        jQuery('input[name="medicine-was-used"]').prop('disabled', true).val('')
        jQuery('.medicine-was-used').hide()
      }
    }
  }

  jQuery('input[name="allergenes-or-chronic"]').on('change', () =>
    toggleChronicDiseases()
  )
  function toggleChronicDiseases() {
    if (jQuery('input[name="allergenes-or-chronic"]:checked').val() === 'yes') {
      jQuery('.toggle-chronic-diseases').prop('disabled', false).val('')
      jQuery('.toggle-chronic-diseases').show()
    } else {
      jQuery('.toggle-chronic-diseases').prop('disabled', true).val('')
      jQuery('.toggle-chronic-diseases').hide()
    }
  }

  jQuery('input[name="has-documentation"]').on('change', () => toggleDocs())
  function toggleDocs() {
    if (jQuery('input[name="has-documentation"]:checked').val() === 'yes') {
      jQuery('input#upload-documentation').prop('disabled', false).val('')
      jQuery('fieldset.upload-documentation').show()
    } else {
      jQuery('input#upload-documentation').prop('disabled', true).val('')
      jQuery('fieldset.upload-documentation').hide()
    }
  }

  jQuery('input[name="allergenes"]').on('change', () => toggleSymptoms())
  function toggleSymptoms() {
    if (jQuery('input[name="allergenes"]:checked').val() === 'yes') {
      jQuery('textarea[name="allergies-description"]')
        .prop('disabled', false)
        .val('')
      jQuery('textarea[name="allergies-description"]').show()
    } else {
      jQuery('textarea[name="allergies-description"]')
        .prop('disabled', true)
        .val('')
      jQuery('textarea[name="allergies-description"]').hide()
    }
  }

  jQuery('input[name="chronic-diseases"]').on('change', () => toggleDiseases())
  function toggleDiseases() {
    if (jQuery('input[name="chronic-diseases"]:checked').val() === 'yes') {
      jQuery('textarea[name="chronic-diseases-description"]')
        .prop('disabled', false)
        .val('')
      jQuery('textarea[name="chronic-diseases-description"]').show()
    } else {
      jQuery('textarea[name="chronic-diseases-description"]')
        .prop('disabled', true)
        .val('')
      jQuery('textarea[name="chronic-diseases-description"]').hide()
    }
  }

  jQuery('input[name="permanent-medications"]').on('change', () =>
    togglepermanentMeds()
  )
  function togglepermanentMeds() {
    if (jQuery('input[name="permanent-medications"]:checked').val() === 'yes') {
      jQuery('.permanent-medications-description')
        .prop('disabled', false)
        .val('')
      jQuery('.permanent-medications-description').show()
    } else {
      jQuery('.permanent-medications-description')
        .prop('disabled', true)
        .val('')
      jQuery('.permanent-medications-description').hide()
    }
  }

  jQuery('input[name="operations"]').on('change', () => toggleOperations())
  function toggleOperations() {
    if (jQuery('input[name="operations"]:checked').val() === 'yes') {
      jQuery('.operations-description').prop('disabled', false).val('')
      jQuery('.operations-description').show()
      jQuery('.complications-field').prop('disabled', false).val('')
      jQuery('.complications-field').show()
    } else {
      jQuery('.operations-description').prop('disabled', true).val('')
      jQuery('.operations-description').hide()
      jQuery('.complications-field').prop('disabled', true).val('')
      jQuery('.complications-field').hide()
    }
  }

  // sprawdzanie zaznaczenia na starcie, nie tylko zmianie

  // Inne funkcje, np. toggleDocs, mogą być również dostępne globalnie lub wewnątrz initializeUpload
}

initializeUpload()

import $ from 'jquery'
import 'selectize'
import Dropzone from 'dropzone'
import { initializeSelectize } from '../../assets/js/select2_homepage.js'
import { initializeSelectizeConsultation } from '../../assets/js/select2_ekonsultacja.js'
import {
  initializeUpload,
  validate_forms,
  dataForm,
} from '../../assets/js/upload.js'
import Sticky from 'sticky-js'

window.uploadedFileCount = 0

Dropzone.autoDiscover = false
let myDropzone

window.$ = $
window.jQuery = $

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('.sticky-button') && window.innerWidth < 640) {
    const button = document.querySelector('.sticky-button')
    let scrolledOnce = false
    let originalStyle = button.getAttribute('style')
    let wrapper = null
    const footer = document.querySelector('footer') // Dodanie stopki

    const handleScroll = () => {
      if (!wrapper) {
        wrapper = document.createElement('div')
        wrapper.style.backgroundColor = 'white'
        wrapper.style.padding = '10px'
        wrapper.style.zIndex = '999'
        wrapper.style.position = 'fixed'
        wrapper.style.bottom = '0'
        wrapper.style.top = 'auto!important'
        wrapper.style.left = '50%'
        wrapper.style.transform = 'translateX(-50%)'
        wrapper.style.width = window.innerWidth + 'px'
        wrapper.style.height = button.offsetHeight * 2 + 'px'
      }

      // ustalanie, kiedy wyłączyć button przy stopce
      const footerRect = footer.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const wrapperBottom = windowHeight - wrapper.offsetHeight

      if (button.classList.contains('is-sticky')) {
        if (!document.body.contains(wrapper)) {
          document.body.appendChild(wrapper)
        }
      } else {
        if (document.body.contains(wrapper)) {
          wrapper.remove()
        }
      }

      if (!scrolledOnce) {
        new Sticky('.sticky-button')
        scrolledOnce = true
      }

      // Sprawdź, czy wrapper nachodzi na stopkę
      if (footerRect.top < wrapperBottom) {
        wrapper.style.display = 'none'
        button.style.display = 'none'
      } else {
        wrapper.style.display = 'block'
        button.style.display = 'flex'
      }

      if (window.scrollY <= 5 && wrapper) {
        wrapper.remove()
        button.setAttribute('style', originalStyle || '')
        scrolledOnce = false
      }
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('touchmove', handleScroll)
  }

  if (document.body.classList.contains('home')) {
    initializeSelectize()
  }

  // /strony konsultacji
  if (
    document.body.classList.contains('page-template-formularz_dzien_po') ||
    document.body.classList.contains('page-template-formularz_antykoncepcja') ||
    document.body.classList.contains('page-template-formularz_zwolnienie') ||
    document.body.classList.contains('page-template-formularz')
  ) {
    initializeUpload()

    const formElement = document.querySelector('#front-page-form')

    Dropzone.autoDiscover = false

    if (formElement) {
      const dropzoneContent = document.querySelector('#dropzone-content')
      const fileInput = document.querySelector('#file') // Ensure this input exists

      let myDropzone = new Dropzone('#dropzone-content', {
        url: `${window.location.protocol}//${window.location.host}/endpoint/`,
        clickable: '.dropzone-content',
        paramName: 'file_upload',
        maxFilesize: 10,
        acceptedFiles: '.pdf,.jpg,.jpeg,.png',
        maxFiles: 3,
        uploadMultiple: true,
        autoProcessQueue: true,
        parallelUploads: 1,
        // Language Strings
        dictFileTooBig: 'Podany plik jest za duży',
        dictInvalidFileType: 'Nieprawidłowy typ pliku',
        dictCancelUpload: 'Anuluj',
        dictRemoveFile: 'Usuń',
        dictMaxFilesExceeded: 'Podany plik jest za duży',
        dictDefaultMessage: 'Wybierz plik/i do przesłania',
        init: function () {
          const dz = this

          const uploadButton = document.querySelector('#upload-button')

          if (uploadButton) {
            uploadButton.addEventListener('click', function (e) {
              e.preventDefault()
              e.stopPropagation()

              if (!validate_forms()) {
                e.preventDefault()
              } else {
                dataForm()
              }
            })
          }

          dz.on('successmultiple', function (files, response) {
            if (response.response === 'SUCCESS' && response.url) {
              console.log('uploaded')
              window.uploadedFileCount += files.length

              const input = document.createElement('input')
              input.type = 'hidden'
              input.name = 'uploaded_files[]'
              input.value = response.url

              // Wstawiamy input do formularza
              const form = document.querySelector('#front-page-form')
              if (form) {
                form.appendChild(input)
              } else {
                console.error(
                  'Nie znaleziono formularza do dodania ukrytego inputa.'
                )
              }
            } else {
              console.error('Błąd w odpowiedzi serwera lub brak URL.')
            }
          })
        },
      })
    }

    initializeSelectizeConsultation()
  }

  const marqueeWrapper = document.querySelector('.marquee-wrapper')
  const marqueeElement = document.querySelector('#marquee')

  if (marqueeWrapper && marqueeElement) {
    const speed = 1
    const content = marqueeElement.innerHTML

    const containerWidth = marqueeWrapper.offsetWidth
    let contentWidth = marqueeElement.scrollWidth

    // Obliczamy przerwę - różnica między szerokością kontenera a szerokością tekstu, minus kilka pikseli
    const gap = containerWidth - contentWidth // Odejmujemy 50px dla lepszego efektu

    // Dodajemy przestrzeń jako przerwę między powielonymi tekstami
    const space = `<span style="display:inline-block; width:${gap}px;"></span>`

    while (contentWidth < containerWidth * 2) {
      marqueeElement.innerHTML += space + content
      contentWidth = marqueeElement.scrollWidth
    }

    const startMarquee = () => {
      let currentPosition = 0

      const animate = () => {
        currentPosition -= speed

        if (currentPosition <= -contentWidth) {
          currentPosition = 0 // Reset pozycji do pełnego wyświetlenia
        }

        marqueeElement.style.transform = `translateX(${currentPosition}px)`

        requestAnimationFrame(animate)
      }

      if (contentWidth > containerWidth) {
        animate()
      }
    }

    startMarquee()
  }
})

// import { initBlock } from "./blocks";

// const blockNames = ["sample-block-1"];

// for (const item of blockNames) {
//   initBlock(item).then((blocks) => {});
// }

import 'flowbite'

export function initializeSelectizeConsultation() {
  const $selectElement = jQuery('select[name="choose-medicine"]')
  let fileUrl

  if (!$selectElement.data('morning-after')) {
    fileUrl = `${uploader.wordpress_host}/wp-content/themes/recepty/zapis_produktow.json`
  } else {
    fileUrl = `${uploader.wordpress_host}/wp-content/themes/recepty/morning_after_products.json`
  }

  if ($selectElement.length) {
    // Inicjalizacja Selectize z AJAX
    const selectizeInstance = $selectElement.selectize({
      valueField: 'id',
      labelField: 'text',
      searchField: 'text',
      placeholder: 'Nazwa leku...',
      create: false,
      allowClear: true,
      load: function (query, callback) {
        if (!query.length) return callback()

        // Zawsze ładujemy wszystkie dane z AJAX-a
        jQuery.ajax({
          url: fileUrl,
          type: 'GET',
          dataType: 'json',
          error: function () {
            callback()
          },
          success: function (res) {
            if (!query.length) {
              callback(res)
            } else {
              const filteredResults = res.filter(item =>
                item.text.toLowerCase().includes(query.toLowerCase())
              )
              callback(filteredResults)
            }
          },
        })
      },
      render: {
        option: function (item) {
          return '<div>' + item.text + '</div>'
        },
        item: function (item) {
          return '<div>' + item.text + '</div>'
        },
      },
      onInitialize: function () {
        const selectizeInstance = this
        // Pobranie opcji za pomocą AJAX-a, aby ustawić domyślną wartość
        let select2LekID = new URLSearchParams(window.location.search).get(
          'lek'
        )
        const defaultOptionId = parseInt(select2LekID, 10) // ID domyślnej opcji

        jQuery.ajax({
          url: fileUrl,
          dataType: 'json',
          success: function (data) {
            // Sprawdź, czy domyślna opcja jest dostępna
            const optionExists = data.some(item => item.id === defaultOptionId)

            if (optionExists) {
              const defaultOption = data.find(
                item => item.id === defaultOptionId
              )

              selectizeInstance.addOption({
                id: defaultOptionId,
                text: defaultOption.text,
              })
              selectizeInstance.setValue(defaultOptionId)
            }
          },
        })
      },
    })
  }
}
